import classNames from "classnames";
import {
    ChangeEvent,
    forwardRef,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    Ref,
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { FieldError } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import { ITranslateProps } from "@finbackoffice/site-core";
import Tooltip from "../tooltip/Tooltip";
import Translate from "../translate/Translate";
import styles from "./input-field.module.sass";

type EventHandler = (e: React.ChangeEvent<HTMLInputElement>) => void;

type ValueType<TValueAsNumber> = TValueAsNumber extends true ? number : string;

export type OnChangeHandler<TValueAsNumber> = (value: ValueType<TValueAsNumber>) => void;

interface Props<
    TType = HTMLInputTypeAttribute,
    TValueAsNumber = boolean | undefined,
    TIsEventHandler = boolean,
> {
    label?: string | ITranslateProps;
    iconClass?: string;
    innerIconClass?: string;
    wrapperClassname?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    tooltip?: {
        render: string;
        variant?: string;
        condition?: string;
    };
    onInnerIconClick?: (e: SyntheticEvent) => void;
    onIconClick?: (e: SyntheticEvent) => void;
    required?: boolean;
    enableAutoComplete?: boolean;
    showFocus?: boolean;
    onChange?: TIsEventHandler extends true ? EventHandler : OnChangeHandler<TValueAsNumber>;
    value?: TType extends "number"
        ? TValueAsNumber extends true | undefined
            ? number
            : string
        : string;
    valueAsNumber?: TValueAsNumber;
    name: string;
    type?: TType;
    isEventHandler?: TIsEventHandler;
}

const Input = forwardRef(
    <TType extends HTMLInputTypeAttribute, TValueAsNumber extends boolean | undefined>(
        {
            label,
            iconClass,
            innerIconClass,
            wrapperClassname,
            error,
            tooltip,
            onInnerIconClick,
            onIconClick,
            required,
            enableAutoComplete,
            showFocus,
            value,
            onChange,
            type,
            name,
            className,
            valueAsNumber,
            isEventHandler,
            ...rest
        }: Props<TType, TValueAsNumber> & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">,
        ref: Ref<HTMLInputElement>,
    ) => {
        const _valueAsNumber = valueAsNumber ?? (type === "number" ? true : false);
        const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
        const inputRef = useRef<any>(undefined);

        useEffect(() => {
            if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
                setAutoComplete("new-password");
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleRefSet = useCallback(
            (input: HTMLInputElement | null) => {
                inputRef.current = input;
                if (typeof ref === "function") {
                    ref(input);
                } else if (ref) {
                    (ref as React.MutableRefObject<HTMLInputElement | null>).current = input;
                }
            },
            [ref],
        );

        useEffect(() => {
            if (showFocus && inputRef?.current.getBoundingClientRect().y < window.innerHeight) {
                inputRef.current.focus();
            }
        }, [showFocus]);

        const handleInputChange = useCallback(
            (e: SyntheticEvent<HTMLInputElement>) => {
                if (isEventHandler) {
                    (onChange as EventHandler)(e as ChangeEvent<HTMLInputElement>);
                } else {
                    const val =
                        type === "number" && _valueAsNumber
                            ? e.currentTarget.valueAsNumber
                            : e.currentTarget.value;

                    if (typeof onChange === "function") {
                        (onChange as OnChangeHandler<TValueAsNumber>)(
                            val as ValueType<TValueAsNumber>,
                        );
                    }
                }
            },
            [onChange, type, _valueAsNumber, isEventHandler],
        );

        return (
            <div className={wrapperClassname}>
                {iconClass && <i className={iconClass} onClick={onIconClick} />}
                {label && (
                    <label htmlFor={name} className={styles.label}>
                        <Translate
                            tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                            namespace={
                                typeof label !== "string"
                                    ? (label as ITranslateProps).namespace
                                    : TranslationScopes.Common
                            }
                        />
                    </label>
                )}
                <div
                    className={classNames({
                        [styles.fieldError]: error,
                        required,
                    })}>
                    <input
                        ref={handleRefSet}
                        type={type}
                        id={name}
                        name={name}
                        onChange={handleInputChange}
                        value={_valueAsNumber ? value || "" : value}
                        className={className || styles.inputStyle}
                        autoComplete={autoComplete}
                        {...rest}
                    />
                    {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                    {error && (
                        <span className={classNames(styles.error, "field-error")}>
                            {error.message}
                        </span>
                    )}
                    {tooltip && (
                        <Tooltip
                            targetRef={inputRef}
                            message={tooltip.render}
                            variant={tooltip.variant}
                            condition={tooltip.condition}
                        />
                    )}
                </div>
            </div>
        );
    },
);

export default Input;

import { FC, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { IExchangeRatePair, IWalletResponse } from "@finbackoffice/clientbff-client";
import { Currency, WalletType, isCryptoCurrency } from "@finbackoffice/enums";
import { CurrencyShortNames } from "@finbackoffice/fe-core";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import { useSubscribeWalletsById } from "hooks";
import Loading from "components/base/loading/Loading";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import styles from "./user-panel.module.sass";

type IWalletItemBlockProps = {
    token: string;
    rate: number | null;
    type?: WalletType;
    fxCurrency: Currency | "";
    balance?: string;
    currency: Currency;
};

const WalletItemBlock: FC<IWalletItemBlockProps> = ({
    currency,
    balance,
    type,
    token,
    rate,
    fxCurrency,
}) => {
    return (
        <>
            <Svg
                src={`/common/currencies/${currency.toLowerCase()}.svg`}
                wrapper="span"
                className={classNames("svg-icon", type === WalletType.Bonus && styles.typeBonus)}
            />
            <div className={styles.currentWalletCurrency}>
                <span>
                    {CurrencyShortNames[currency] || currency}
                    {balance && (
                        <CurrencyFormatter currency={currency} amount={balance} withCode={false} />
                    )}
                </span>
                <div className={styles.currency}>
                    {type === WalletType.Bonus && (
                        <Svg
                            src="/common/desktop/base-icons/bonus-label.svg"
                            wrapper="span"
                            className={styles.bonusLabel}
                        />
                    )}
                    {(isCryptoCurrency(currency) || type === WalletType.Bonus) && (
                        <span
                            className={classNames(type === WalletType.Bonus && styles.bonusWallet)}>
                            <Translate
                                tid={`currencies_${
                                    type === WalletType.Bonus ? "bonus" : currency.toLowerCase()
                                }`}
                            />
                        </span>
                    )}
                    {isCryptoCurrency(currency) && (
                        <span>
                            {token ? (
                                currency !== fxCurrency ? (
                                    rate ? (
                                        <>
                                            <i>≈</i>
                                            {rate && balance && (
                                                <CurrencyFormatter
                                                    currency={fxCurrency}
                                                    amount={(parseFloat(balance) * rate).toString()}
                                                    withCode={false}
                                                    withSymbol
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Loading />
                                    )
                                ) : null
                            ) : (
                                <CurrencyFormatter
                                    currency={Currency.USD}
                                    amount="0"
                                    withCode={false}
                                    withSymbol
                                />
                            )}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

type IProps = {
    rates: IExchangeRatePair[];
    wallet: Partial<IWalletResponse>;
    fxCurrency: Currency | "";
    currentWallet: Partial<IWalletResponse>;
    token: string;
};

const WalletItem: FC<IProps> = ({ wallet, rates, fxCurrency, currentWallet, token }) => {
    const { setSelectedWallet } = useContext(UserAccountContext);
    const { setBetError } = useContext(BetSlipContext);
    const { currency, balance, type } = wallet;

    const rate = useMemo(() => {
        const result = parseFloat(rates.find((pair) => pair.toCurrency === fxCurrency)?.rate ?? "");
        return !Number.isNaN(result) ? result : 0;
    }, [rates, fxCurrency]);

    const handleWalletChange = () => {
        setSelectedWallet(wallet);
        setBetError(null);
    };

    if (!currency) {
        return null;
    }

    return (
        <li
            data-testid={`currencies-dropdown-choice-${currency}`}
            className={classNames(
                currency === currentWallet.currency &&
                    type === currentWallet.type &&
                    styles.selected,
                currentWallet.type === WalletType.Bonus && styles.bonus,
            )}
            onClick={handleWalletChange}>
            <WalletItemBlock
                token={token || ""}
                rate={rate}
                type={type}
                fxCurrency={fxCurrency}
                balance={balance}
                currency={currency}
            />
        </li>
    );
};

const WalletSelector: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteConfigReady, siteSupportedWallets } = useContext(ConfigContext);
    const { userCurrencyExchangeRate, exchangeRates, fxCurrencyExchangeRate } =
        useContext(ExchangeRatesContext);
    const { currentWallet, currentBalance, userCurrency } = useContext(UserAccountContext);
    const { userToken } = useContext(AuthContext);
    const [showBalance, setShowBalance] = useState(true);
    const [openDropdown, setOpenDropdown] = useState(false);
    const dropdownRef: RefObject<HTMLDivElement | null> = useRef(null);
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;

    useSubscribeWalletsById();

    useEffect(() => {
        const outsideClickHandler = (event: any) => {
            if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
                setOpenDropdown(false);
            }
        };

        if (openDropdown) {
            document.addEventListener("mousedown", outsideClickHandler);
        } else {
            document.removeEventListener("mousedown", outsideClickHandler);
        }

        return () => {
            document.removeEventListener("mousedown", outsideClickHandler);
        };
    }, [openDropdown]);

    return (
        <div className={styles.balance}>
            <span
                className={`${showBalance ? styles.showBalance : styles.hideBalance}`}
                onClick={() => setShowBalance(!showBalance)}
            />
            <i className={styles.separator} />
            {siteConfigReady && showBalance && currentWallet?.currency ? (
                <div
                    onClick={() => setOpenDropdown(!openDropdown)}
                    ref={dropdownRef}
                    data-testid="currencies-dropdown-toggle">
                    <WalletItemBlock
                        token={userToken || ""}
                        rate={fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate}
                        type={currentWallet.type}
                        fxCurrency={fiatEnabled ? userCurrency : fxCurrency}
                        balance={currentBalance}
                        currency={currentWallet.currency}
                    />
                    {openDropdown && (
                        <ul className={styles.balanceDropdown}>
                            {siteSupportedWallets.map((wallet: Partial<IWalletResponse>, index) => (
                                <WalletItem
                                    key={index}
                                    rates={exchangeRates?.[wallet.currency as Currency] || []}
                                    wallet={wallet}
                                    fxCurrency={fiatEnabled ? userCurrency : fxCurrency}
                                    currentWallet={currentWallet}
                                    token={userToken || ""}
                                />
                            ))}
                        </ul>
                    )}
                </div>
            ) : (
                <span onClick={() => setShowBalance(!showBalance)}>
                    <Translate tid="userPanel_showBalance" />
                </span>
            )}
            {!siteConfigReady && <Loading wrapperClassName={styles.balanceLoader} />}
        </div>
    );
};

export default WalletSelector;

import { FC, useContext } from "react";
import { ModalsContext } from "@finbackoffice/site-core";
import Modal from "../../base/modal/Modal";
import { ModalTypes } from "../../../utils/constants";
import styles from "./verification-kyc.module.sass";
import VerificationKyc from "./VerificationKyc";

const KycVerificationModal: FC = () => {
    const { kycVerifyModalRef } = useContext(ModalsContext);

    return (
        <Modal
            ref={kycVerifyModalRef}
            type={ModalTypes.VERIFICATION_KYC}
            closable={false}
            styleClass={styles.verificationKycModal}
            priority={true}>
            <VerificationKyc />
        </Modal>
    );
};

export default KycVerificationModal;
